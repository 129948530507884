import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import ResponsiveSpacing from "../components/responsive-spacing"
import Garnish from "../components/garnish"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ButtonColorNav from "../components/bottom-color-nav"
import SVG from "../components/svg"

const ShippingInfoStyled = styled.div`
  text-align: center;
  max-width: 840px;
  margin: 0 auto;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    letter-spacing: 2px;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.Blue};
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
    font-size: 1.4em;
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
  }

  .left {
    border-right: 1px solid ${(p) => p.theme.DarkBlue};
  }
  .right {
    border-left: 1px solid ${(p) => p.theme.DarkBlue};
  }

  .left,
  .right {
    flex-flow: column;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 50%;

    h2 {
      font-size: 6em;
      @media (max-width: 768px) {
        line-height: 0.9em;
        font-size: 4em;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .bottom {
    clear: both;
    flex-flow: column;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid ${(p) => p.theme.DarkBlue};

    h2 {
      font-size: 6em;

      @media (max-width: 768px) {
        line-height: 0.9em;
        font-size: 4em;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const ShippingInfo = ({
  residential_shipping,
  commercial_shipping,
  order_by_time,
}) => {
  return (
    <ShippingInfoStyled>
      <div
        className="left"
        dangerouslySetInnerHTML={{ __html: residential_shipping }}
      />
      <div
        className="right"
        dangerouslySetInnerHTML={{ __html: commercial_shipping }}
      />
      <div
        className="bottom"
        dangerouslySetInnerHTML={{ __html: order_by_time }}
      />
    </ShippingInfoStyled>
  )
}

const ContentStyled = styled.div`
  max-width: 840px;
  margin: 123px auto 50px auto;
  font-size: 10px;

  h1 {
    text-align: center;
    font-size: 9.2em;
    margin: 0;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    @media (max-width: 768px) {
      line-height: 0.9em;
      font-size: 5rem;
    }
  }
  h2 {
    font-size: 2.4em;
    color: ${(p) => p.theme.Blue};
    margin: 10px 0;
  }
  p {
    padding: 0;
    margin: 10px 0 24px 0;
    color: ${(p) => p.theme.DarkBlue};
  }
  a {
    color: ${(p) => p.theme.DarkBlue};
  }
  .center {
    text-align: center;
    padding: 10px 0 60px 0;
  }
`

const Content = ({ title, content, acf }) => {
  return (
    <ContentStyled>
      <ResponsiveSpacing>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <div className="center">
          <SVG name="SWave" color={"orange"} />
        </div>
        <ShippingInfo {...acf} />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ResponsiveSpacing>
      <Garnish
        garnish={acf.margin_images[0]}
        pSpeed={-0.3}
        top={300}
        rotation={0}
        rSpeed={0}
        pos={"left"}
      />
      <Garnish
        garnish={acf.margin_images[1]}
        pSpeed={-0.3}
        top={800}
        rotation={0}
        rSpeed={-0}
        pos={"right"}
      />
    </ContentStyled>
  )
}

const ShippingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "shipping-page" } }) {
        edges {
          node {
            content
            title
            acf {
              residential_shipping
              commercial_shipping
              order_by_time
              search_title
              share_headline
              search_description
              share_comment
              share_image {
                source_url
              }
              margin_images {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node
  const { title, content, acf } = fields

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = acf

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <Content title={title} acf={acf} content={content} />
      <ButtonColorNav />
    </Layout>
  )
}

export default ShippingPage
